<template>
  <div>
    <el-row>
      <el-col style="text-align: right; margin-bottom: 1rem">
        <el-button
          type="primary"
          plain
          medium
          @click="$router.push({ name: 'admin_tracker' })"
        >
          <font-awesome-icon icon="arrow-left" fixed-width></font-awesome-icon>
          Tracker Settings
        </el-button>
      </el-col>

      <el-card style="margin-bottom: 1rem">
        <tracker-supermetrics-accounts-table></tracker-supermetrics-accounts-table>
      </el-card>

      <el-card>
        <tracker-supermetrics-fields-table></tracker-supermetrics-fields-table>
      </el-card>
    </el-row>
  </div>
</template>

<script>
import TrackerSupermetricsFieldsTable from "@/components/Tables/TrackerSupermetricsFieldsTable"
import TrackerSupermetricsAccountsTable from "@/components/Tables/TrackerSupermetricsAccountsTable"

export default {
  name: "SupermetricsAdmin",
  components: {
    TrackerSupermetricsFieldsTable,
    TrackerSupermetricsAccountsTable,
  },
}
</script>

<style scoped lang="scss">
</style>
