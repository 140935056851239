<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix modal-heading">
      <span>Seed Accounts</span>
      <el-button type="text" @click="$modal.hide('supermetrics-accounts-seed')">
        <font-awesome-icon icon="window-close" fixed-width></font-awesome-icon>
      </el-button>
    </div>
    <el-form
      @submit.native.prevent="submit"
      :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
      label-width="100px"
    >
      <el-alert
        title="Accounts are automatically stored on a nightly basis. This is legacy functionality left incase those automatic updates fail. Only use this form if you’re sure there is an issue with that functionality."
        type="warning"
        style="margin-bottom: 0.5rem"
      ></el-alert>
      <el-form-item
        :error="
          form.errors.first('service') ? form.errors.first('service') : null
        "
        label="Service"
        required
      >
        <el-select
          v-model="data.service"
          size="small"
          @focus="form.errors.clear('service')"
        >
          <el-option label="Google" value="AW"></el-option>
          <el-option label="Facebook" value="FA"></el-option>
          <el-option label="Bing" value="AC"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :error="form.errors.first('json') ? form.errors.first('json') : null"
        label="Data"
        required
      >
        <el-input
          type="textarea"
          rows="4"
          @focus="form.errors.clear('json')"
          v-model="data.json"
          style="font-family: monospace"
        ></el-input>
        <p class="help-text">
          JSON formatted data directly from Supermetrics. Click
          <a
            href="https://docs.google.com/document/d/1dw2MP69X7x4k4xtatq1uVkEEPoMEf5E0TTK2t5OkZtU/edit?usp=sharing"
            target="_blank"
            >here</a
          >
          to learn more.
        </p>
      </el-form-item>

      <el-button
        type="primary"
        plain
        medium
        native-type="submit"
        v-loading="loading"
        :style="{ width: '100%' }"
      >
        Seed Accounts
      </el-button>

      <p class="help-text">
        Seeding accounts will remove all previously seeded accounts for the
        selected service.
      </p>
    </el-form>
  </el-card>
</template>

<script>
import Form from "form-backend-validation"
import { EventBus } from "@/events"

export default {
  name: "TrackerSupermetricsAccountsSeedForm",
  data() {
    return {
      form: new Form(),
      loading: false,
      data: {
        service: "AW",
        json: "",
      },
    }
  },
  methods: {
    async submit() {
      this.loadng = true
      await this.axios
        .post("supermetrics/accounts/seed", this.data)
        .then(() => {
          EventBus.$emit("supermetrics-account-success")
          this.$message.success("Successfully seeded account data.")
          this.$modal.hide("supermetrics-accounts-seed")
        })
        .catch((resp) => {
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
</style>
