<template>
  <div>
    <data-tables
      :data="current"
      :pagination-props="paginationDef"
      :table-props="tableProps"
      :show-action-bar="false"
      :filters="[searchDef]"
      style="width: 100%"
      v-loading="loading"
      class="tracker-table"
      :current-page="2"
    >
      <el-row class="table-header" slot="tool">
        <el-col class="table-header-name">
          <span>Supermetrics Accounts</span>
        </el-col>
        <el-col class="table-header-container">
          <div style="flex: 1; margin-right: 0.5rem">
            <el-select v-model="selected" size="small">
              <el-option label="Google" value="AW"></el-option>
              <el-option label="Facebook" value="FA"></el-option>
              <el-option label="Bing" value="AC"></el-option>
            </el-select>
          </div>
          <div style="flex: 1; margin-right: 0.5rem">
            <el-input
              v-model="searchDef.value"
              placeholder="Search By Account Name..."
              size="small"
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div style="flex: 0; display: flex">
            <el-button type="primary" @click="openSeedModal" size="small">
              <font-awesome-icon
                icon="database"
                fixed-width
              ></font-awesome-icon>
              Seed
            </el-button>
            <el-tooltip effect="dark" placement="bottom">
              <div slot="content">
                Click here to manually update accounts. Accounts are
                automatically updated on a daily basis.
              </div>
              <el-button @click="update" type="primary" size="small">
                <font-awesome-icon
                  icon="sync-alt"
                  :spin="loading"
                  fixed-width
                ></font-awesome-icon>
                Update Accounts
              </el-button>
            </el-tooltip>
          </div>
        </el-col>
      </el-row>

      <el-table-column
        prop="name"
        label="Name"
        sortable="custom"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        prop="external_id"
        label="ID"
        sortable="custom"
        show-overflow-tooltip
      ></el-table-column>
    </data-tables>
  </div>
</template>

<script>
import TrackerSupermetricsAccountsSeedForm from "@/components/Forms/TrackerSupermetricsAccountsSeedForm"
import { EventBus } from "@/events"

export default {
  name: "TrackerSupermetricsAccountsTable",
  data() {
    return {
      data: [],
      selected: "AW",
      loading: false,
      searchDef: {
        value: "",
        prop: "name",
      },
      sortMethod(a, b) {
        if (isNaN(a)) {
          return a.localeCompare(b)
        } else {
          return a > b ? 1 : a < b ? -1 : 0
        }
      },
      paginationDef: {
        pageSize: 20,
        pageSizes: [20],
        currentPage: 1,
        layout: "prev, pager, next",
      },
      tableProps: {
        border: false,
        stripe: true,
        defaultSort: {
          prop: "name",
          order: "ascending",
        },
      },
    }
  },
  computed: {
    current() {
      if (this.data.length === 0) return []
      return this.data[this.selected]
    },
  },
  methods: {
    openSeedModal() {
      this.$modal.show(
        TrackerSupermetricsAccountsSeedForm,
        {},
        {
          name: "supermetrics-accounts-seed",
          adaptive: true,
          height: "auto",
          scrollable: true,
        }
      )
    },
    async fetch() {
      this.loading = true
      await this.axios
        .get("supermetrics/accounts")
        .then(({ data }) => (this.data = data.data))
      this.loading = false
    },
    async update() {
      this.loading = true
      await this.axios
        .get("supermetrics/accounts/update?raw=true")
        .then(({ data }) => {
          this.$message.success("Successfully updated Supermetrics accounts.")
          this.data = data.data
        })
        .catch((resp) => {
          this.$root.globalRequestError(resp)
        })
      this.loading = false
    },
  },
  mounted() {
    this.fetch()

    EventBus.$on("supermetrics-account-success", () => this.fetch())
  },
}
</script>

<style scoped lang="scss">
</style>
